.skills-section {
    padding: 50px 170px;
}

.skills-section h2 {
    font-size: 35px;
    margin: 0;
}

#frontend, #backend, #database, #tools {
    display: flex;
    justify-content: space-between;
    gap: 250px; 
    padding-top: 50px;
}

.skills-section h3 {
    font-size: 22px;
    margin: 0;
    min-width: 100px;
    max-width: 250px;
}

.skill-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 20px 0;
}

.skill-item {
    text-align: center;
    width: 120px;
}

.skill-item img {
    height: 55px;
}

.skill-item p {
    font-size: 17px;
}