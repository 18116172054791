.contact-section {
    padding: 150px 20px;
    background: linear-gradient(180deg, #ffffff 0%, #ffe3f3 70%);
    position: relative;
    display: flex;
    gap: 100px;
    justify-content: center;
    align-items: center;
}

.contact-section h1 {
    font-size: 70px;
    margin: 0;
}

.contact-section h3 {
    font-weight: 100;
}

#bold {
    font-weight: bold;
}

.contact-section a, .contact-section a:visited {
    color: black;
    text-decoration: none;
}

.email u:hover, .social-links a:hover {
    color: rgb(249, 136, 211);
}