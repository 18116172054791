.about-section {
    padding: 50px 170px;
    background: rgb(246, 246, 246);
}

.about-container {
    position: relative;
    display: flex;
    gap: 100px;
    justify-content: center;
    align-items: center;
}

.about-section h2 {
    font-size: 35px;
    margin: 0;

}

.about-section p {
    font-size: 18px;
}
