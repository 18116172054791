.experiences-section {
    padding: 50px 170px;
}

.experiences-section h2 {
    font-size: 35px;
    margin: 0;
    padding-bottom: 16px;
}

.experience-name h3 {
    font-size: 22px;
    line-height: 10px;
}

.experience-details {
    font-size: 18px;
}

.experience-item {
    padding: 12px 0;
}

#experience-date {
    font-size: 20px;
    color: gray;
    font-weight: 100;
    font-style: italic;
}

.pink {
    color: #ff6cb5;
}