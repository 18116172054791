.header-section {
    margin: 0;
    padding: 0;
}

.header-nav {
    padding: 20px 20px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center; 
    list-style: none;
    text-align: center;
    width: 100%;
    top: 0;
    z-index: 100;
    position: fixed;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.header-nav li {
    margin: 0 60px;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    transition: color 0.3s ease;
}

.header-nav li a {
    color: #000000;
    text-decoration: none;
}

.header-nav li a:hover {
    color: rgb(249, 136, 211);
}

.header-nav li a:visited {
    text-decoration: none;
}