.projects-section {
    padding: 50px 170px;
    background: rgb(246, 246, 246);
}

.projects-section h2 {
    font-size: 35px;
    margin: 0;
}

.project-link {
    text-decoration: none;
    display: block;
}

.project-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
    transform: scale(1);
}

.card {
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
    max-width: 500px;
    margin: 20px auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: scale(1.03); 
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.project-image {
    position: relative;
    overflow: hidden;
    height: 220px;
}

.project-image img {
    width: 100%;
    height: 100%; 
    display: block;
    object-fit: cover;
    object-position: top;
}

.project-content {
    padding: 0px 20px 20px 20px;
    text-align: left;
}

.project-content h3 {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin: 15px 0 0 0;
}

.project-content p {
    font-size: 16px;
}

.project-card a:visited, .project-card a {
    color: black; 
    text-decoration: none; 
}
