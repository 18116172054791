.main-section {
    padding: 150px 170px;
    background: linear-gradient(180deg, #ffe3f3 0%, #ffffff 70%);
    display: flex;
    gap: 100px;
    justify-content: center;
    align-items: center;
}

.main-section h1 {
    font-size: 65px;
    margin: 0;
}

.main-section h2 {
    font-size: 20px;
    font-weight: 100;
}

.text-container {
    align-items: left;
    text-align: left;
}

.button-container {
    padding: 30px 0;
}

.button-container button {
    padding: 20px 50px;
    border: none;
    border-radius: 8px;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: bold;
    margin-right: 30px;
}

.button-container #about {
    background-color: rgb(255, 161, 224);
    
}

.button-container #about:hover {
    background-color: rgb(232, 146, 203);
    
}

.button-container #contact {
    background-color: #ffffff;
    border: 2px solid black;
}

.image-container img {
    width: 300px;
    border-radius: 7%;
}
